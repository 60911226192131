import Script from "next/script";

/**
 *
 * see this for the exact information neede
 * https://heapanalytics.com/app/env/2087615681/install
 */
export function HeapAnalyticInstallationScript() {
  let project_id: string | null = "2087615681"; //prodction
  if (process.env.NODE_ENV === "development") {
    project_id = null;
  }

  if (!project_id) {
    return null;
  }

  return (
    <>
      <Script id="heap-installation-code" strategy="afterInteractive">
        {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
        heap.load("2087615681");`}
      </Script>
    </>
  );
}
