import {
  useDisclosure,
  useColorMode,
  useColorModeValue,
  Box,
  Icon,
  VStack,
  CloseButton,
  Button,
  chakra,
  Flex,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { useViewportScroll } from "framer-motion";
import Link from "next/link";
import React, { MutableRefObject } from "react";
import {
  AiFillHome,
  AiOutlineInbox,
  AiFillGithub,
  AiOutlineMenu,
} from "react-icons/ai";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { FaMoon, FaSun, FaHeart } from "react-icons/fa";
import Image from "next/image";
import AppLogo from "../images/logo.png";
import AppLogoDark from "../images/logo-dark.png";

export function Header() {
  const mobileNav = useDisclosure();

  const { toggleColorMode: toggleMode } = useColorMode();
  const text = useColorModeValue("dark", "light");
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  const bg = useColorModeValue("white", "gray.800");
  const ref = React.useRef<HTMLElement | undefined>();
  const [y, setY] = React.useState(0);
  const { height = 0 } = ref.current ? ref.current.getBoundingClientRect() : {};

  const { scrollY } = useViewportScroll();
  React.useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()));
  }, [scrollY]);

  const SponsorButton = (
    <Box
      display={{ base: "none", md: "flex" }}
      alignItems="center"
      as="a"
      aria-label="Sponsor Choc UI on Open Collective"
      href={""}
      target="_blank"
      rel="noopener noreferrer"
      bg="gray.50"
      borderWidth="1px"
      borderColor="gray.200"
      px="1em"
      minH="36px"
      rounded="md"
      fontSize="sm"
      color="gray.800"
      outline="0"
      transition="all 0.3s"
      _hover={{
        bg: "gray.100",
        borderColor: "gray.300",
      }}
      _active={{
        borderColor: "gray.200",
      }}
      _focus={{
        boxShadow: "outline",
      }}
      ml={5}
    >
      <Icon as={FaHeart} w="4" h="4" color="red.500" mr="2" />
      <Box as="strong" lineHeight="inherit" fontWeight="semibold">
        Sponsor
      </Box>
    </Box>
  );
  const MobileNavContent = (
    <VStack
      pos="absolute"
      top={0}
      left={0}
      right={0}
      display={mobileNav.isOpen ? "flex" : "none"}
      flexDirection="column"
      p={2}
      pb={4}
      m={2}
      bg={bg}
      spacing={3}
      rounded="sm"
      shadow="sm"
    >
      <CloseButton
        aria-label="Close menu"
        justifySelf="self-start"
        onClick={mobileNav.onClose}
      />
      {/* <Button w="full" variant="ghost" leftIcon={<AiFillHome />}>
        Dashboard
      </Button>
      <Button w="full" variant="solid" leftIcon={<AiOutlineInbox />}>
        Inbox
      </Button>
      <Button w="full" variant="ghost" leftIcon={<BsFillCameraVideoFill />}>
        Videos
      </Button> */}
    </VStack>
  );
  return (
    <chakra.div position={"sticky"} top={0} zIndex={500}>
      <Box pos="relative" zIndex={200}>
        <chakra.header
          ref={ref as MutableRefObject<HTMLElement>}
          shadow={y > height ? "sm" : undefined}
          transition="box-shadow 0.2s"
          bg={bg}
          // borderTop="6px solid"
          borderTopColor="brand.400"
          w="full"
          overflowY="hidden"
        >
          <chakra.div h="4.5rem" mx="auto" maxW="1200px">
            <Flex
              w="full"
              h="full"
              px="6"
              align="center"
              justify="space-between"
            >
              <Flex flex="0 1 auto" alignItems={"center"} cursor="pointer">
                <Link href="/search" passHref>
                  <Image
                    className="app-logo" // fuck next/image,need to !important to workaround  styling issue: https://github.com/vercel/next.js/discussions/18312
                    alt="logo"
                    objectFit="cover"
                    src={useColorModeValue(AppLogo, AppLogoDark)}
                  />
                </Link>
              </Flex>

              <Flex
                flex="0 1 auto"
                justify="flex-end"
                w="full"
                maxW="824px"
                align="center"
                color="gray.400"
              >
                <IconButton
                  size="md"
                  fontSize="lg"
                  aria-label={`Switch to ${text} mode`}
                  variant="ghost"
                  color="current"
                  ml={{ base: "0", md: "3" }}
                  onClick={toggleMode}
                  icon={<SwitchIcon />}
                />
                <IconButton
                  display={{ base: "flex", md: "none" }}
                  aria-label="Open menu"
                  fontSize="20px"
                  color={useColorModeValue("gray.800", "inherit")}
                  variant="ghost"
                  icon={<AiOutlineMenu />}
                  onClick={mobileNav.onOpen}
                />
              </Flex>
            </Flex>
            {MobileNavContent}
          </chakra.div>
        </chakra.header>
      </Box>
    </chakra.div>
  );
}
