import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { AppProps } from "next/app";
import Head from "next/head";
import { QueryClient, QueryClientProvider } from "react-query";
import { Header } from "../components/Header";
import { useGA } from "../lib/ga";
import "../styles/globals.css";
import {
  HeapAnalyticInstallationScript,
  GoogleAnalyticScripts,
} from "../components/thirdparty";
import { DefaultSeo } from "next-seo";

const queryClient = new QueryClient();
// 2. Define the new text styles
const theme = extendTheme({
  colors: {
    green: {
      100: "#4ada7e",
      200: "#43c471",
      300: "#3bae65",
      400: "#349958",
      500: "#2c834c",
      600: "#256d3f",
      700: "#1e5732",
      800: "#164126",
      900: "#07160d",
    },
  },
  styles: {
    global: {
      html: {
        overflowY: "scroll", // prevent layout from shifting
      },
      h1: {
        fontSize: "4xl",
        fontWeight: "bold",
      },
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  useGA();
  return (
    <QueryClientProvider client={queryClient}>
      <DefaultSeo
        openGraph={{
          type: "website",
          locale: "en_IE",
          url: "https://mobilecompare.co/",
          site_name: "MobileCompare",
          title:
            "MobileCompare | Best website to compare mobile and plan prices across all telcos",
          description: `MobileCompare collects the latest prices and products information across major telco websites`,
          images: [
            {
              url: "https://mobilecompare.s3.ap-southeast-1.amazonaws.com/open-graph-home-v1.png",
              width: 1200,
              height: 630,
              alt: "Open graph image",
            },
          ],
        }}
        additionalMetaTags={[
          {
            // Obtain a good, BIG/LARGE image in rich link preview in Telegram
            // https://stackoverflow.com/a/55924961/4927172
            name: "twitter:card",
            content: "summary_large_image",
          },
          {
            // Obtain a good, BIG/LARGE image in rich link preview in Telegram
            // https://stackoverflow.com/a/55924961/4927172
            name: "twitter:image",
            content:
              "https://mobilecompare.s3.ap-southeast-1.amazonaws.com/open-graph-home-v1.png",
          },
        ]}
      />
      <ChakraProvider theme={theme}>
        <GoogleAnalyticScripts />
        <HeapAnalyticInstallationScript />
        <Head>
          <title>MobileCompare</title>
          <meta
            name="description"
            content="Find the best deal for mobile and plan in mobilecompare.co. We collect the latest prices and products information across major telco websites to help you save time and money."
          ></meta>
          <link rel="shortcut icon" href="/favicon.ico" />
        </Head>
        <Header />
        <Component {...pageProps} />
      </ChakraProvider>
    </QueryClientProvider>
  );
}
export default MyApp;
