import Script from "next/script";

/**
 *
 * see this for the exact information neede
 * @returns https://analytics.google.com/analytics/web/#/a221001384p304373044/admin/streams/table/3256545901
 */
export function GoogleAnalyticScripts() {
  return (
    <>
      <Script
        id="google-analytic-lib"
        src="https://www.googletagmanager.com/gtag/js?id=G-RC41B8ZPQE"
        strategy="afterInteractive"
      />
      <Script id="google-analytic-code" strategy="afterInteractive">
        {` window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-RC41B8ZPQE');`}
      </Script>
    </>
  );
}
